import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { JobStatus, JobPostStatus } from '../../../generated/types';
import { convertEnumToString } from '../../../utils/enum';

import { Pill } from '../../atoms/Pill';

import {
  ApplyIcon,
  CheckIcon,
  DatabaseIcon,
  DateIcon,
  DeleteFullIcon,
  DrivingIcon,
  EditIcon,
  ExternalLinkIcon,
  HandShakeFullIcon,
  LocationIcon,
  PendingIcon,
  PhoneIcon,
  PublishIcon,
  StarIcon,
  TransitIcon,
  TrophyIcon,
  UnPublishIcon,
  WishFullIcon,
} from '../../atoms/Icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../atoms/Tooltip';
import { Copy } from '../../atoms/Typography';
import { IconButton } from '../../atoms/Icons/Icon';
import { SuitcaseFullIcon } from '../../atoms/Icons/SuitcaseIcon';
import { Avatar } from '../../atoms/Avatar';
import { formatDate } from '../../../utils/date';

export const JobTableAgency: React.FC<any> = ({
  agencyName,
  agentName,
  agentPhone,
  isThroughAgency,
}: JobTableAgencyProps) => {
  return (
    <Container>
      {isThroughAgency || agencyName || agentName ? (
        <>
          {Boolean(agencyName) && <AgencyName>{agencyName}</AgencyName>}
          {Boolean(agentName) && <AgentName>{agentName}</AgentName>}
          {Boolean(agentPhone) && (
            <AgentPhone>
              <PhoneIcon color={Colors.Grey} size={1} />
              {agentPhone}
            </AgentPhone>
          )}
        </>
      ) : (
        <NoAgency>No Agency</NoAgency>
      )}
    </Container>
  );
};

export const JobTableCompany: React.FC<any> = ({
  companyAvatar,
  company,
  companyLocationCity,
  companyDistances,
}: JobTableCompanyProps) => {
  const { distance: transitDistance, duration: transitDuration } = companyDistances?.transit || {};
  const { distance: drivingDistance, duration: drivingDuration } = companyDistances?.driving || {};
  const hasTransit = Boolean(transitDistance) && Boolean(transitDuration) && transitDistance !== 'unknown';
  const hasDriving = Boolean(drivingDistance) && Boolean(drivingDuration) && drivingDistance !== 'unknown';

  return (
    <Container>
      <CompanyName>
        {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
        {company}
      </CompanyName>
      {companyLocationCity && (
        <CompanyLocation>
          <LocationIcon color={Colors.Grey} size={1} />
          {companyLocationCity}
        </CompanyLocation>
      )}
      {transitDistance && transitDuration && (
        <CompanyDirection>
          {hasTransit ? (
            <>
              <TransitIcon color={Colors.Grey} size={1} />
              {transitDistance && `${transitDistance}`}
              {transitDuration && <i>({transitDuration})</i>}
            </>
          ) : hasDriving ? (
            <>
              <DrivingIcon color={Colors.Grey} size={1} />
              {drivingDistance && `${drivingDistance}`}
              {drivingDuration && <i>({drivingDuration})</i>}
            </>
          ) : null}
        </CompanyDirection>
      )}
    </Container>
  );
};

export const JobTablePosition: React.FC<any> = ({ jobTitle, jobTitleUrl, isNew, onClick }: JobTablePositionProps) => {
  return (
    <Container onClick={onClick}>
      <JobTitle>
        {jobTitle}
        {jobTitleUrl && (
          <JobTitleUrl target="_blank" to={jobTitleUrl}>
            <ExternalLinkIcon size={1} />
          </JobTitleUrl>
        )}

        {!!isNew && (
          <Tooltip
            title={
              <IconButton
                style={{
                  transform: 'translateY(-10px)',
                }}
              >
                <StarIcon size={0.6} />
              </IconButton>
            }
          >
            <Copy styleLevel={1}>New Job Application</Copy>
          </Tooltip>
        )}
      </JobTitle>
    </Container>
  );
};

export const JobTableRate: React.FC<any> = ({ rate, duration, ir35 }: JobTableRateProps) => {
  return (
    <Container>
      <Rate>
        {rate}

        {Boolean(ir35) && (
          <Ir35>
            <Tooltip
              title={
                <IconButton>
                  <Copy styleLevel={3}>ir35</Copy>
                </IconButton>
              }
            >
              <RateIr35>Contract is within IR35</RateIr35>
            </Tooltip>
          </Ir35>
        )}
      </Rate>
      {Boolean(duration) && (
        <Duration>
          <DateIcon color={Colors.Grey} size={1} />
          {duration}
        </Duration>
      )}
    </Container>
  );
};

export const JobTableStatus: React.FC<any> = ({ status }: JobTableStatusProps) => {
  let color = Colors.GreyDarker;
  let backgroundColor = Colors.PrimaryLightest;
  let icon = <></>;
  switch (status) {
    case JobStatus.Scrapped:
      icon = <DatabaseIcon color={Colors.White} size={1.1} />;
      color = Colors.White;
      backgroundColor = Colors.BlackLightest;
      break;
    case JobStatus.Wishlist:
      icon = <WishFullIcon color={Colors.Contrast} size={1.1} />;
      backgroundColor = Colors.White;
      break;
    case JobStatus.Applied:
      icon = <ApplyIcon color={Colors.White} size={1.1} />;
      color = Colors.GreyDarkest;
      backgroundColor = Colors.PrimaryLightest;
      break;
    case JobStatus.Interview:
      icon = <SuitcaseFullIcon color={Colors.White} size={1.1} />;
      color = Colors.White;
      backgroundColor = Colors.PrimaryDarkest;
      break;
    case JobStatus.Offer:
      icon = <TrophyIcon color={Colors.PrimaryDarkest} size={1.2} />;
      backgroundColor = Colors.ContrastLightest;
      break;
    case JobStatus.Accepted:
      icon = <HandShakeFullIcon color={Colors.White} size={1} />;
      color = Colors.White;
      backgroundColor = Colors.ContrastDarkest;
      break;
    case JobStatus.Closed:
      icon = <DeleteFullIcon color={Colors.White} size={1} />;
      color = Colors.White;
      backgroundColor = Colors.GreyDarkest;
      break;
  }
  const label = convertEnumToString('JobStatus', status);
  return (
    <Container>
      <Pill color={color} fillColor={backgroundColor} isSmall={true} isBold={true} icon={icon}>
        {label}
      </Pill>
    </Container>
  );
};

export const JobPostTableStatus: React.FC<any> = ({ status }: JobPostTableStatusProps) => {
  let color = Colors.GreyDarker;
  let backgroundColor = Colors.PrimaryLightest;
  let icon = <></>;
  switch (status) {
    case JobPostStatus.Draft:
      icon = <EditIcon color={Colors.Contrast} size={1.3} />;
      backgroundColor = Colors.White;
      break;
    case JobPostStatus.PendingReview:
      icon = <PendingIcon color={Colors.PrimaryDarkest} size={1} />;
      backgroundColor = Colors.ContrastLightest;
      break;
    case JobPostStatus.Approved:
      icon = <CheckIcon color={Colors.PrimaryDarkest} size={1} />;
      backgroundColor = Colors.ContrastLightest;
      break;
    case JobPostStatus.Unpublished:
      icon = <UnPublishIcon color={Colors.White} size={1.1} />;
      color = Colors.GreyDarkest;
      backgroundColor = Colors.PrimaryLightest;
      break;
    case JobPostStatus.Published:
      icon = <PublishIcon color={Colors.White} size={1.1} />;
      color = Colors.White;
      backgroundColor = Colors.PrimaryDarkest;
      break;
  }
  const label = convertEnumToString('JobPostStatus', status);
  return (
    <Container>
      <Pill color={color} $fillColor={backgroundColor} isSmall={true} isBold={true} icon={icon}>
        {label}
      </Pill>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const JobTableDate: React.FC<any> = ({
  status,
  appliedAt,
  updatedAt,
  interviewedAt,
  offerAt,
  rejectedAt,
  acceptedAt,
}: JobTableDateProps) => {
  let color = Colors.GreyDarker;
  let label = '';
  switch (status) {
    case JobStatus.Scrapped:
      color = Colors.White;
      label = `Imported on ${formatDate({ date: updatedAt })}`;
      break;
    case JobStatus.Wishlist:
      color = Colors.Primary;
      label = `Updated on ${formatDate({ date: appliedAt })}`;
      break;
    case JobStatus.Applied:
      color = Colors.Primary;
      label = `Applied on ${formatDate({ date: appliedAt })}`;
      break;
    case JobStatus.Interview:
      color = Colors.White;
      label = `Interviewed on ${formatDate({ date: interviewedAt })}`;
      break;
    case JobStatus.Offer:
      color = Colors.Contrast;
      label = `Offer on ${formatDate({ date: offerAt })}`;
      break;
    case JobStatus.Accepted:
      color = Colors.ContrastDarkest;
      label = `Accepted on ${formatDate({ date: acceptedAt })}`;
      break;
    case JobStatus.Closed:
      color = Colors.Black;
      label = `Closed on ${formatDate({ date: rejectedAt })}`;
      break;
  }
  return (
    <Container>
      <span
        style={{
          color: color,
          fontSize: '0.8rem',
          fontWeight: 'bolder',
        }}
      >
        {label}
      </span>
    </Container>
  );
};

const Rate = styled.div`
  color: ${Colors.PrimaryDarkest};
  font-size: 1.4rem;
  display: flex;
  font-weight: bolder;
`;

const Ir35 = styled.div`
  transform: translateY(-8px);
`;

const RateIr35 = styled.div`
  display: flex;
  font-size: 0.6rem;
  font-weight: bolder;
  cursor: pointer;
`;

const Duration = styled.div`
  display: flex;
  color: ${Colors.GreyLight};
  font-size: 0.9rem;
  gap: 4px;
`;

const AgencyName = styled.div`
  color: ${Colors.GreyDarkest};
  font-size: 1.1rem;
  font-weight: bolder;
`;

const AgentName = styled.div`
  color: ${Colors.GreyLight};
  font-size: 1rem;
`;

const AgentPhone = styled.div`
  display: flex;
  color: ${Colors.Grey};
  font-size: 0.8rem;
  gap: 4px;
`;

const NoAgency = styled.div`
  display: flex;
  color: ${Colors.Grey};
  font-size: 1rem;
`;

const CompanyName = styled.div`
  color: ${Colors.GreyDarkest};
  font-size: 1.1rem;
  font-weight: bolder;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CompanyLocation = styled.div`
  display: flex;
  color: ${Colors.GreyLight};
  font-size: 0.9rem;
  gap: 4px;
`;

const CompanyDirection = styled.div`
  display: flex;
  color: ${Colors.GreyLight};
  font-size: 0.9rem;
  gap: 4px;
`;

const JobTitle = styled.div`
  color: ${Colors.GreyDarkest};
  font-size: 1.3rem;
  text-decoration: underline;
  text-decoration-color: ${Colors.PrimaryDarker};
  cursor: pointer;
  display: flex;
  color: ${Colors.PrimaryDarkest};
  font-weight: bolder;
`;

const JobTitleUrl = styled(Link)`
  transform: translateY(-8px);
`;

interface JobTableProps {}

interface JobTableRateProps extends JobTableProps {
  rate?: string;
  duration?: string;
  ir35?: boolean;
}

interface JobTableStatusProps extends JobTableProps {
  status: JobStatus;
}

interface JobTableDateProps extends JobTableProps {
  status: JobStatus;
  updatedAt: string;
  appliedAt: string;
  interviewedAt: string;
  offerAt: string;
  rejectedAt: string;
  acceptedAt: string;
}

interface JobPostTableStatusProps extends JobTableProps {
  status: JobPostStatus;
}

interface JobTableAgencyProps extends JobTableProps {
  agencyName?: string;
  agentName?: string;
  agentPhone?: string;
  isThroughAgency?: boolean;
}

interface JobTableCompanyProps extends JobTableProps {
  companyAvatar?: string;
  company?: string;
  companyDistances?: any;
  companyLocationCity?: string;
}

interface JobTablePositionProps extends JobTableProps {
  jobTitleUrl?: string;
  jobTitle: string;
  onClick?: () => void;
  isNew?: boolean;
}
