import { Publish } from '@styled-icons/material-outlined';
import { Icon, IconProps } from './Icon';

export function PublishIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Publish />
    </Icon>
  );
}
